import { createOnAction, ReduxAction, registerActions } from "@pihanga/core";

export const ACTION_TYPES = registerActions("IVCAP", [
  "INIT",

  "LOAD_ORDER_LIST",
  "ORDER_LIST",
  "LOAD_ORDER_RECORD",
  "ORDER_DETAIL",
  "CREATE_ORDER",
  "ORDER_RECEIPT",
  "LOAD_ORDER_PRODUCT_LIST",
  "ORDER_PRODUCT_LIST",

  "LOAD_SERVICE_LIST",
  "SERVICE_LIST",
  "LOAD_SERVICE_RECORD",
  "SERVICE_DETAIL",

  "LOAD_ARTIFACT_LIST",
  "ARTIFACT_LIST",
  "LOAD_ARTIFACT_RECORD",
  "ARTIFACT_DETAIL",
  "LOAD_ARTIFACT_DATA",
  "ARTIFACT_DATA",
  "UPLOAD_ARTIFACT_DATA",
  "UPLOADED_ARTIFACT_RECORD",
  "UPLOAD_ARTIFACT_PARTIAL",
  "UPLOAD_ARTIFACT_PROGRESS",

  "LOAD_METADATA_LIST",
  "METADATA_LIST",
  "LOAD_METADATA_RECORD",
  "METADATA_DETAIL",
  "QUERY_METADATA",
  "QUERY_RESULT",
  "ADD_METADATA",
  "METADATA_ADDED",
  "UPDATE_METADATA",
  "METADATA_UPDATED",
  "REVOKE_METADATA",
  "METADATA_REVOKED",

  "ERROR",
  "NOT_AUTHORIZED",
]);

export type BaseEvent<T = {}> = {
  apiURL: URL;
  template?: Template<T>;
};

export const DEF_LIST_LIMIT = 10;

export type LoadListEvent<T = {}> = BaseEvent<T> & {
  limit?: number;
  page?: string;
  filter?: string; // "name ~= 'Scott%'"
  orderBy?: string; //
  orderDesc?: boolean; // set to true if return list in descending order
  atTime?: Date; // return result as of
};

export type ListEvent = {
  nextPage?: string;
};

export function createListAction(
  type: string,
  ev: LoadListEvent
): ReduxAction & LoadListEvent {
  return {
    type,
    apiURL: ev.apiURL,
    page: ev.page,
    filter: ev.filter,
    limit: ev.limit || DEF_LIST_LIMIT,
    orderBy: ev.orderBy,
    orderDesc: ev.orderDesc,
    template: ev.template,
  };
}

export type ErrorAction = ReduxAction & {
  message: string;
  source: string;
  cause: any;
  requestAction: ReduxAction;
};

export const onIvcapError = createOnAction<ErrorAction>(ACTION_TYPES.ERROR);
export const onIvcapUnauthorizedError = createOnAction<ErrorAction>(
  ACTION_TYPES.NOT_AUTHORIZED
);

export type LoadRecordEvent<T = {}> = BaseEvent<T> & {
  id: string;
};

export function createReadAction(
  type: string,
  ev: LoadRecordEvent
): ReduxAction & LoadRecordEvent {
  return {
    type,
    ...ev,
  };
}

export type Template<T> = ReduxAction & T;
