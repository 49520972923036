import { PiRegister } from "@pihanga/core";

import { init as flexGridInit } from "./flexGrid";
import { init as tbAnalyticCard } from "./tbAnalyticCard";
import { init as tbPageInit } from "./tbPage";
import { init as confirmDeleteInit } from "./tbConfirmDelete";
import { init as datatableInit } from "./tbDataTable";
import { init as emptyListInit } from "./tbEmptyList";
import { init as genericCompInit } from "./tbGenericComponent";
import { init as tbImgSegmentViewer } from "./tbImgSegmentViewer";
import { init as landingPageInit } from "./tbLandingPage";
import { init as customLoginPageInit } from "./tbLoginPage";
import { init as participantInfoInit } from "./tbParticipantInfo";
import { init as projectDetailInit } from "./tbProjectDetail";
import { init as orderDetailInit } from "./tbOrderDetail";
import { init as stepsInit } from "./tbSteps";
import { init as cardInit } from "./tbCard";
import { init as gmapInit } from "./googleMap";

export function init(register: PiRegister): void {
  flexGridInit(register);
  tbAnalyticCard(register);
  tbPageInit(register);
  confirmDeleteInit(register);
  datatableInit(register);
  emptyListInit(register);
  genericCompInit(register);
  tbImgSegmentViewer(register);
  landingPageInit(register);
  customLoginPageInit(register);
  participantInfoInit(register);
  projectDetailInit(register);
  orderDetailInit(register);
  stepsInit(register);
  cardInit(register);
  gmapInit(register);
}
